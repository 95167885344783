import React from "react";
import serviceS1 from "@/images/containers1.png";
import serviceS2 from "@/images/s2.jpg";
import ContainerSpecifications from "./containers-table";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <h2>Used Shipping Containers</h2>
      <p>
      At Palm Desert Trading, we offer high-quality used shipping containers tailored to meet various needs across industries. Our inventory includes a range of container sizes and conditions, ideal for applications such as storage solutions, mobile offices, and custom-built projects. Each container undergoes thorough inspection and refurbishment to ensure it meets industry standards for durability and functionality.

Whether you're looking for a cost-effective solution for secure storage, a versatile space for business operations, or a foundation for innovative projects, Palm Desert Trading provides reliable options that combine affordability with performance. Our expertise in sourcing and managing used shipping containers allows us to deliver exceptional value and service to our clients.
      </p>
      <img src={serviceS1} alt="" />
        <h2>Container Specifications</h2>
        <ContainerSpecifications />
      </div>
  );
};

export default ServiceDetailsContent;
