import React from 'react';

// Container data with dimensions in meters, rounded, and filtered
const containerData = [
  {
    type: '20 ft General Container',
    externalDimensions: '6.10 m x 2.44 m x 2.59 m',
    internalDimensions: '5.90 m x 2.35 m x 2.38 m',
    tareWeight: '2,200 kg',
    maximumPayload: '23,600 kg',
    cubicCapacity: '33 CBM',
  },
  {
    type: '20 ft Open Top Container',
    externalDimensions: '6.10 m x 2.44 m x 2.59 m',
    internalDimensions: '5.92 m x 2.35 m x Variable',
    tareWeight: '2,223 kg',
    maximumPayload: '28,245 kg',
    cubicCapacity: '32.7 CBM',
  },
  {
    type: '20 ft Flat Rack Container',
    externalDimensions: '6.10 m x 2.44 m x 2.41 m',
    internalDimensions: '5.67 m x 2.35 m x 2.35 m',
    tareWeight: '2,300 kg',
    maximumPayload: '30,500 kg',
    cubicCapacity: '32.7 CBM',
  },
  {
    type: '20 ft Refrigerated Container',
    externalDimensions: '6.10 m x 2.44 m x 2.59 m',
    internalDimensions: '5.30 m x 2.35 m x 2.29 m',
    tareWeight: '3,080 kg',
    maximumPayload: '28,290 kg',
    cubicCapacity: '28.3 CBM',
  },
  {
    type: '40 ft General Container',
    externalDimensions: '12.19 m x 2.44 m x 2.59 m',
    internalDimensions: '12.03 m x 2.35 m x 2.36 m',
    tareWeight: '3,719 kg',
    maximumPayload: '26,710 kg',
    cubicCapacity: '67.7 CBM',
  },
  {
    type: '40 ft Open Top Container',
    externalDimensions: '12.19 m x 2.44 m x 2.59 m',
    internalDimensions: '12.03 m x 2.35 m x 2.34 m',
    tareWeight: '3,814 kg',
    maximumPayload: '26,681 kg',
    cubicCapacity: '66.7 CBM',
  },
  {
    type: '40 ft Flat Rack Container',
    externalDimensions: '12.19 m x 2.44 m x 2.13 m',
    internalDimensions: '11.89 m x 2.35 m x 2.13 m',
    tareWeight: '5,000 kg',
    maximumPayload: '40,000 kg',
    cubicCapacity: '62.2 CBM',
  },
  {
    type: '40 ft Refrigerated Container',
    externalDimensions: '12.19 m x 2.44 m x 2.59 m',
    internalDimensions: '11.49 m x 2.35 m x 2.31 m',
    tareWeight: '4,423 kg',
    maximumPayload: '29,500 kg',
    cubicCapacity: '67.6 CBM',
  },
  {
    type: '40 ft High Cube Container',
    externalDimensions: '12.19 m x 2.44 m x 2.91 m',
    internalDimensions: '12.03 m x 2.35 m x 2.69 m',
    tareWeight: '3,900 kg',
    maximumPayload: '29,500 kg',
    cubicCapacity: '76.2 CBM',
  },
];

const ContainerSpecifications = () => (
  <div style={{ width: '90%', margin: 'auto', overflow: 'hidden' }}>
    <p>At Palm Desert Trading, we offer a diverse range of used shipping containers with the following detailed specifications:</p>
    
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', width: '25%' }}>Container Type</th>
          <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', width: '25%' }}>External Dimensions</th>
          <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', width: '25%' }}>Internal Dimensions</th>
          <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', width: '10%' }}>Tare Weight</th>
          <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', width: '10%' }}>Maximum Payload</th>
          <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', width: '10%' }}>Cubic Capacity</th>
        </tr>
      </thead>
      <tbody>
        {containerData.map((container, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid #ddd', padding: '12px' }}>{container.type}</td>
            <td style={{ border: '1px solid #ddd', padding: '12px' }}>{container.externalDimensions}</td>
            <td style={{ border: '1px solid #ddd', padding: '12px' }}>{container.internalDimensions}</td>
            <td style={{ border: '1px solid #ddd', padding: '12px' }}>{container.tareWeight}</td>
            <td style={{ border: '1px solid #ddd', padding: '12px' }}>{container.maximumPayload}</td>
            <td style={{ border: '1px solid #ddd', padding: '12px' }}>{container.cubicCapacity}</td>
          </tr>
        ))}
      </tbody>
    </table>
    
    <p>Each container is meticulously inspected and refurbished to ensure it meets high standards of reliability and durability. Choose Palm Desert Trading for dependable container solutions that cater to your storage and transport needs.</p>
  </div>
);

export default ContainerSpecifications;
