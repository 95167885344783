import React from "react";
import { LogoImage } from "@/data";

const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={light} alt="" />
              </a>
              <p>
                Palm Desert Trading is a globally oriented trading company based
                in Jordan, specializing in a wide range of products and
                services. Our unwavering commitment to quality and excellence
                has firmly established us as a reliable partner in the
                international trade industry.
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              <p>
                PO Box 4028 Jordan
                <br />
                11953 Amman
              </p>
              <p>
                P: <a href="tel:0096265522668">+962 6 5 522 668 </a>
              </p>
              <p>
                E: <a href="mailto:info@palmdt.com"> info@palmdt.com </a>
              </p>
            </aside>
          </div>
          <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul>
                {/* <li>
                  <a href="#">
                    <i className="fa fa-instagram"></i>Instagram
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://www.facebook.com/PDTJOR?mibextid=LQQJ4d"
                    target="_blank"
                  >
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/palm-desert"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i>Linkedin
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} by{" "}
              <a href="#">palmdt.com</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
