import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Services</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="#">Used Shipping Containers</a>
            </li>
            <li>
              <a href="#">Container Specifications</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>Secure Your Shipping Containers Today</h4>
          <p>
          Transform your shipping container sourcing and trading experience with unmatched reliability and expertise. Embrace the value of high-quality containers, the benefits of diverse container types, and the assurance of our dedicated service. Partner with Palm Desert Trading today and embark on a journey of acquiring top-notch containers that meet all your storage and transport needs. Discover the Excellence in Shipping Container Trading.
          </p>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
